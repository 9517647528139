.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {f
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.standalone-table-container {
  width: 100%;
  display: block;
  min-height: 100%;
  height: 0px;
  overflow-y: scroll;
}

.standalone-table-container::-webkit-scrollbar {
  width: 0.5em;

}
.standalone-table-container::-webkit-scrollbar-track {
  color: green;
  background-color: #DDD;
  border-radius: 0 10px 10px 0;
}
.standalone-table-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;  
  border-radius: 0 10px 10px 0;
}

.standalone-table{
  font-size: 14px;
  color: #333;
}

.standalone-table tr th{
  background: #c6d1dd;
  padding: 1em; 
  color: #333;
}
.standalone-table tr th:hover{
  background: #ddd;
}

.standalone-table thead th{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.standalone-table tr th:first-child{
  border-radius: 10px 0 0 0 !important;
}
.standalone-table tr th:last-child{
  border-radius: 0 0px 0 0 !important;
}


.standalone-table tr td{
  background: white;
  padding: 1em;
  border-bottom: 1px solid grey;
}

.standalone-table tr:hover td{
  background: #f0f0f0;
}

.standalone-pagination{
  font-size: 14px;
  display: flex;
  list-style: none;
}


.standalone-pagination .page-item{
  padding: 0.5em 1em;
}
.standalone-pagination .page-item.active{
  border: 1px solid black;
  padding: 0.3em 0.8em;
  border-radius: 7px;
}

.standalone-button{
  background: #00305f !important;
  border: 0 !important;
}

.standalone-button:hover{
  background: #1a456f !important;
  border: 0 !important;
}

.standalong-table-loading{
  position: absolute; 
  z-index: 100;
  background: lightgrey;
  opacity: 0.5;
  transition: 1s all;
}

.modal .modal-content{
  position: absolute;
  top: 0%;
  opacity: 0;
  -webkit-transition: 0.5s all 0s;
  -moz-transition: 0.5s all 0s;
  -ms-transition: 0.5s all 0s;
  -o-transition: 0.5s all 0s;
  transition: 0.5s all 0s;
  display: block !important;
  background-color: white;
  border-color: #DDD;
}
.modal.show .modal-content{
  opacity: 1;
  top: 10%;
}
.modal .modal-body{
  overflow: auto;
  /* height: 30vh; */
}

.w-33{
  width: 33%;
}

.standalone-modal-dialog{
  position: absolute !important;
  width: 400px  !important;
  top: 0% !important; 
  left: 25%; 
  /* left: calc(100% - (400px * 2)); */
  opacity: 0 !important;
  -webkit-transition: 0.5s all 0s  !important;
  -moz-transition: 0.5s all 0s  !important;
  -ms-transition: 0.5s all 0s  !important;
  -o-transition: 0.5s all 0s  !important;
  transition: 0.5s all 0s  !important;
  display: block !important;

}
.standalone-modal-dialog.show{
  opacity: 1 !important;
  top: 10% !important;
}

.badge.bg-primary:hover{
  background-color: #3d8bfd !important;
}

.badge.bg-success:hover{
  background-color: #75b798 !important;
}
/* .standalone-switch .react-switch-bg{
  background-color: #00305f !important;
} */