.w-33{
  width: 33%;
}
.w-66{
  width: 66%;
}
.standalone-chip{
  border: 1px solid lightgray;
  border-radius: 10px;
  cursor: pointer;
}
.standalone-chip-selected{
  background-color: lightgray;
  opacity: 0.5;
  color: #111;
  font-weight: bold;  
}
.standalone-chip:hover{
  border-color: black;
}