:root {
  --sidebar-user-width: 300px;
}

.sidebar-user{
  position: fixed;
  right: 0px;
  background: #1a456f;
  width: var(--sidebar-user-width);
  margin-right: calc(-1*var(--sidebar-user-width));
  height: 100vh;
  transition: all 0.3s;
  color: #DDD;
}

.sidebar-user.show{
  margin-right: 0;
}

.Toastify__toast--success.continuity-toast-message div[role="progressbar"]{
  background: green !important;
}
.Toastify__toast--error.continuity-toast-message div[role="progressbar"]{
  background: red !important;
}